import { Button, Result } from 'antd'
import React, { useEffect } from 'react'
import { Link } from 'gatsby'
import { httpGet } from '../../services/http'
import './style.less'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'

export default function Step1Page() {
    const data = useStaticQuery(graphql`
        query {
            s1: file(relativePath: { eq: "select-exp.png" }) {
                childImageSharp {
                    fluid(maxWidth: 400, maxHeight: 500) {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            }
        }
    `)
    useEffect(() => {
        httpGet('/landing/article/guide-step-1/read')
    }, [])

    return (
        <>
            <div id="progressBarContainer">
                <div
                    id="progressBar"
                    style={{
                        transform: 'scale(0.22,1)',
                    }}
                />
            </div>
            <Result
                icon={
                    <Img
                        className="guide-image"
                        alt="calendar example"
                        imgStyle={{objectFit: 'contain'}}
                        fluid={data.s1.childImageSharp.fluid}
                    />
                }
                title="Start on the Calendar Page by inserting your exemption period"
                subTitle={
                    <div style={{ maxWidth: 550, margin: 'auto', textAlign: 'left' }}>
                        <p>
                            Click "+ Add period" to define your “Exemption Period” by the date,
                            where you leave Denmark for a minimum period of 6 months.
                        </p>
                        <p>Click "start" to select your start date by input in the calendar.</p>
                        <p>(You can leave the end date open, if not yet known)</p>
                        <p>
                            It is optional to give the exemption period a title. (Default is date
                            range)
                        </p>
                    </div>
                }
                extra={[
                    <Link to="/app/">
                        <Button>skip</Button>
                    </Link>,
                    <Link to="/onboarding/pre-guide/">
                        <Button>back</Button>
                    </Link>,
                    <Link to="/onboarding/step2/">
                        <Button type="primary">Next</Button>
                    </Link>,
                ]}
            />
        </>
    )
}
